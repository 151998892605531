import * as Sentry from '@sentry/vue';

const logger = (error) => {
    if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
        Sentry.captureException(
            error,
        );
    } else {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export { logger as default };
