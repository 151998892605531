import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        jwtToken: null,
        mode: null,
        signature: null,
        url: null,
        hasSecondaryCustomer: false,
        isPrimaryCustomer: false,
        isSecondaryCustomer: false,
        hasPrimaryCustomerSigned: false,
        hasSecondaryCustomerSigned: false,
        customerType: null,
    },
    mutations: {
        setJwtToken: (state, payload) => {
            Vue.set(state, 'jwtToken', payload);
        },
        setMode: (state, payload) => {
            let mode = payload;

            if (mode === 'customer_offer') {
                mode = 'customerOffer';
            }
            if (mode === 'maintenance_offer') {
                mode = 'maintenanceOffer';
            }

            Vue.set(state, 'mode', mode);
        },
        setSignature: (state, payload) => {
            Vue.set(state, 'signature', payload);
        },
        setUrl: (state, payload) => {
            Vue.set(state, 'url', payload);
        },
        setHasSecondaryCustomer: (state, hasSecondaryCustomer) => {
            Vue.set(
                state,
                'hasSecondaryCustomer',
                hasSecondaryCustomer,
            );
        },
        setIsPrimaryCustomer: (state, isPrimaryCustomer) => {
            Vue.set(
                state,
                'isPrimaryCustomer',
                isPrimaryCustomer,
            );
        },
        setIsSecondaryCustomer: (state, isSecondaryCustomer) => {
            Vue.set(
                state,
                'isSecondaryCustomer',
                isSecondaryCustomer,
            );
        },
        setHasPrimaryCustomerSigned: (state, hasPrimaryCustomerSigned) => {
            Vue.set(
                state,
                'hasPrimaryCustomerSigned',
                hasPrimaryCustomerSigned,
            );
        },
        setHasSecondaryCustomerSigned: (state, hasSecondaryCustomerSigned) => {
            Vue.set(
                state,
                'hasSecondaryCustomerSigned',
                hasSecondaryCustomerSigned,
            );
        },
        setCustomerType: (state, customerType) => {
            Vue.set(
                state,
                'customerType',
                customerType,
            );
        },
    },
};
