import Vue from 'vue';
import Router from 'vue-router';
import StepLoader from '../components/shared/StepLoader.vue';
import InitError from '../components/shared/status/InitError.vue';
import InitExpired from '../components/shared/status/InitExpired.vue';

import {
    beforeEach,
    afterEach,
} from './globalGuards';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            /**
             * This route can't be part of any scenario, as it is used when something goes
             * wrong before scenario is loaded.
             */
            path: '/init-fehler',
            component: InitError,
        },
        {
            /**
             * This route can't be part of any scenario, as it is used when something goes
             * wrong before scenario is loaded.
             */
            path: '/abgelaufen',
            component: InitExpired,
        },
        {
            path: '/:route?',
            component: StepLoader,
        },
    ],
});

router.beforeEach((to, from, next) => beforeEach(to, from, next));
router.afterEach((to, from) => afterEach(to, from));

export default router;
