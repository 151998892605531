import routeAliases from '@/config/routeAliases';
import { getStepObjectFromRoute } from '@/utils/scenarioUtils';

/**
 * Dead ends for every scenarios. As soon as
 */
export default {
    methods: {
        setAppStateToError() {
            const { mode } = this.$store.state.settings;

            if (mode) {
                this.$router.replace(routeAliases[mode].error);
                this.$store.commit(
                    'scenario/setCurrentStep',
                    getStepObjectFromRoute(routeAliases[mode].error),
                );
            } else {
                /**
                 * In the case where the app doesn't even get a valid "mode" (normally provided by
                 * the backend), we trigger a generic error. This error cannot be contract-specific
                 * as the "mode" is missing.
                 */
                this.$store.commit(
                    'scenario/setCurrentStep',
                    {
                        content: {
                            headline: 'Fehler',
                            status_message: 'Es tut uns Leid! Leider konnte die Auftragserteilung im Moment nicht durchgeführt werden. Bitte probieren Sie zu einem späteren Zeitpunkt erneut, über den Ihnen zugesandten Link Ihren Vertrag mit uns abzuschließen.',
                        },
                    },
                );

                if (!this.$route || this.$route.path !== '/init-fehler') {
                    this.$router.replace('init-fehler');
                }
            }

            this.$store.commit('status/common/setIsAppLoading', false);
            this.$store.commit('status/common/setIsAppCriticalError', true);
        },
        setAppStateToSuccess() {
            const { mode } = this.$store.state.settings;

            this.$router.replace(routeAliases[mode].success);
            this.$store.commit(
                'scenario/setCurrentStep',
                getStepObjectFromRoute(routeAliases[mode].success),
            );
            this.$store.commit('status/common/setIsOfferSigned', true);
        },
        setAppStateToExpired() {
            /**
             * In the case where the contract is expired, the backend response doesn't contain any
             * contract data. Therefore the expired state is triggered before any scenario is
             * actually loaded.
             */
            this.$store.commit(
                'scenario/setCurrentStep',
                {
                    content: {
                        headline: 'Angebot abgelaufen',
                        status_message: 'Der Link zu Ihrem Angebot ist abgelaufen. Kontaktieren Sie bitte Ihren Heizungsplaner.',
                    },
                },
            );

            this.$router.replace('abgelaufen');

            this.$store.commit('status/common/setIsAppLoading', false);
        },
    },
};
