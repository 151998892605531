import store from '../store/index';

/**
 * Programmatically adding customerOffer items to the Cart, as there is no user input
 * required in this type of contract.
 */
const fillCart = (mode) => {
    if (mode === 'customerOffer') {
        store.commit(
            'cart/addCartTotal',
            {
                price: store.state.contractData.price.with_tax,
                label: 'Gesamtpreis',
                uid: 'price_with_tax',
            },
        );
    }

    if (mode === 'contracting') {
        store.commit(
            'cart/addCartItem',
            {
                price: store.state.contractData.monthly_gross,
                label: 'Monatliche Vollservice-Rate',
                uid: 'monthly_gross',
            },
        );
        store.commit(
            'cart/addCartTotal',
            {
                price: store.state.contractData.monthly_gross,
                label: 'Monatliche Vollservice-Rate',
                uid: 'monthly_gross',
                suffix: 'monatlich',
            },
        );
    }
};

export default fillCart;
