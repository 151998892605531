import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        session_id: undefined,
    },
    mutations: {
        /**
         * Helping sorting the events data in Analytics.
         * Generates a unique identifier for each user session. The value doesn't change unless a
         * full refresh of the application is performed.
         */
        generateTrackingSessionId(state) {
            const timestamp = Date.now();
            const random = Math.floor(Math.random() * 100000);
            Vue.set(state, 'session_id', parseInt(timestamp + random, 10));
        },
    },
};
