import { scroller } from 'vue-scrollto/src/scrollTo';
import { getStepObjectFromRoute } from '../utils/scenarioUtils';
import store from '../store';
import routeAliases from '../config/routeAliases';
import { eT } from '../utils/eventTracking';

const scrollTo = scroller();

const beforeEach = (to, from, next) => {
    /**
     * Rules that prevent unexpected user behaviour.
     */
    if (
        // Ensure we don't block the root path.
        to.path !== '/'
        // Ensure this step exists in the scenario.
        && getStepObjectFromRoute(to.params.route)
    ) {
        if (store.state.status.common.isOfferSigned) {
            /**
             * Enforce the "success" page when the offer has been signed, or was signed already.
             */
            next(routeAliases[store.state.settings.mode].success);
        } else if (store.state.status.common.isAppCriticalError) {
            /**
             * Enforce the "error" page when the app crashed.
             */
            next(routeAliases[store.state.settings.mode].error);
        } else {
            next();
        }
    } else {
        /**
         * Authorize a specific route, off-scenario, in case the app crashes during the app
         * initialization phase.
         */
        if (
            to.path === '/init-fehler'
            || to.path === '/abgelaufen'
        ) {
            next();
        }

        /**
         * Disable the navigation to the required route, as is isn't a valid one.
         */
        next(false);
    }
};

const afterEach = (to, from) => {
    /**
     * Automatically scroll to the question when navigating from step to step.
     */
    // We're excluding the app first load.
    if (from.path !== '/') {
        // setTimeout() is necessary to ensure the element has been rendered.
        setTimeout(() => {
            scrollTo('.headline__wrapper');
        }, 1);
    }

    /**
     * Event tracking.
     */
    eT({
        event: 'page_loaded',
        current_virtual_url: to.params.route,
    });
};

export {
    beforeEach,
    afterEach,
};
