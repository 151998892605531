<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          thermondo {{ currentYear }} |
          <a href="https://www.thermondo.de/intern/impressum/" target="_blank">
            Impressum
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    data() {
        return {
            currentYear: process.env.VUE_APP_CURRENT_YEAR,
        };
    },
};
</script>
