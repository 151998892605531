import urlJoin from 'proper-url-join';
import store from '@/store';
import axiosInstance from '@/axios/instance';
import apiSettings from '@/config/api';
import { prepareSignaturePayload } from '@/utils/dataAdapter';

/**
 * Decode JWT token.
 */
const decodeJwt = () => {
    const {
        jwtToken,
    } = store.state.settings;

    const url = urlJoin(
        apiSettings.backend.rootUrl,
        '/api/jwt-decode/',
        {
            query: {
                token: jwtToken,
            },
            trailingSlash: true,
        },
    );

    return axiosInstance.get(
        url,
    );
};

/**
 * Get contract data from backend. Shared across all contract types.
 */
const getContractData = () => {
    const {
        signature,
        url,
        jwtToken,
    } = store.state.settings;

    const contractUrl = urlJoin(
        apiSettings.backend.rootUrl,
        url,
        {
            query: {
                signature,
            },
            trailingSlash: true,
        },
    );

    return axiosInstance.get(
        contractUrl,
        {
            headers: {
                Authorization: `JWT ${jwtToken}`,
            },
        },
    );
};

/**
 * Sign contract, passing the necessary information to the backend if any.
 * Shared across all contract types.
 */
const signContract = () => {
    const {
        url,
        jwtToken,
        mode,
    } = store.state.settings;

    /**
     * TODO: Temporary, until backend aligns the verbs to something common to all contract types.
     * See: https://thermondo.atlassian.net/browse/BE-717
     */
    const settings = {
        customerOffer: {
            method: 'post',
            urlSuffix: 'order',
        },
        contracting: {
            method: 'post',
            urlSuffix: 'submit',
        },
        maintenanceOffer: {
            method: 'put',
            urlSuffix: '',
        },
    };

    const signContractUrl = urlJoin(
        apiSettings.backend.rootUrl,
        url,
        settings[mode].urlSuffix,
        {
            trailingSlash: true,
        },
    );

    return axiosInstance[settings[mode].method](
        signContractUrl,
        prepareSignaturePayload(),
        {
            headers: {
                Authorization: `JWT ${jwtToken}`,
            },
        },
    );
};

/**
 * Validate an IBAN value against the backend.
 */
const validateIbanCondition = () => store.state.collectedData[store.state.settings.mode]
    .bankAccountIban;
const validateIban = () => {
    const {
        jwtToken,
    } = store.state.settings;

    const validateIbanUrl = urlJoin(
        apiSettings.backend.rootUrl,
        'api/validate-iban',
        {
            query: {
                iban: store.state.collectedData[store.state.settings.mode].bankAccountIban,
            },
            trailingSlash: true,
        },
    );

    return axiosInstance.get(
        validateIbanUrl,
        {
            headers: {
                Authorization: `JWT ${jwtToken}`,
            },
        },
    );
};

export {
    decodeJwt,
    getContractData,
    signContract,
    validateIban,
    validateIbanCondition,
};
