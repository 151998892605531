import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        isAppLoading: true,
        isAppCriticalError: false,
        isOfferSigned: false,
        isStepValid: false,
        showHelpTextContent: false,
        showButtonLoader: false,
        showModalError: false,
        showModalWebsite: false,
        modalWebsitePageId: null,
        showModalDefault: false,
        modalDefaultTitle: null,
        modalDefaultBody: null,
    },
    mutations: {
        setIsAppLoading: (state, isAppLoading) => {
            Vue.set(
                state,
                'isAppLoading',
                isAppLoading,
            );
        },
        setIsOfferSigned: (state, isOfferSigned) => {
            Vue.set(
                state,
                'isOfferSigned',
                isOfferSigned,
            );
        },
        setIsAppCriticalError: (state, isAppCriticalError) => {
            Vue.set(
                state,
                'isAppCriticalError',
                isAppCriticalError,
            );
        },
        setIsStepValid: (state, isStepValid) => {
            Vue.set(
                state,
                'isStepValid',
                isStepValid,
            );
        },
        showHelpTextContent: (state, showHelpTextContent) => {
            Vue.set(
                state,
                'showHelpTextContent',
                showHelpTextContent,
            );
        },
        showButtonLoader: (state, showButtonLoader) => {
            Vue.set(
                state,
                'showButtonLoader',
                showButtonLoader,
            );
        },
        showModalError: (state, showModalError) => {
            Vue.set(
                state,
                'showModalError',
                showModalError,
            );
        },
        showModalWebsite: (state, payload) => {
            Vue.set(
                state,
                'showModalWebsite',
                payload.show,
            );
            Vue.set(
                state,
                'modalWebsitePageId',
                payload.websitePageId,
            );
        },
        showModalDefault: (state, payload) => {
            Vue.set(
                state,
                'showModalDefault',
                payload.show,
            );
            Vue.set(
                state,
                'modalDefaultTitle',
                payload.title,
            );
            Vue.set(
                state,
                'modalDefaultBody',
                payload.body,
            );
        },
    },
};
