import find from 'lodash/find';
import store from '../store/index';

/**
 * Returns expected scenario module by dynamically importing the ES6 module.
 * @param mode: {String}
 * @returns {Object|null}
 */
const getScenario = async (mode) => {
    let scenarioData;

    // eslint-disable-next-line
    await import('../scenarios/' + mode)
        .then((module) => {
            scenarioData = module.default();
        });

    return scenarioData;
};

/**
 * Returns step object from route.
 * @param route: {String}
 * @returns {Object}
 */
const getStepObjectFromRoute = (route) => find(store.state.scenario.steps, { route });

export {
    getScenario,
    getStepObjectFromRoute,
};
