<template>
  <div>
    <Headline />
    <HelpText />
    <div class="container-fluid step-loader">

      <!-- Progress bar -->
      <div class="row">
        <div class="col-sm">
          <ProgressBar />
        </div>
      </div>

      <!-- Step content -->
      <div class="row">
        <div :class="[showNav ? 'col-md-7 col-lg-8' : 'col-md-12']">
          <ValidationObserver ref="stepObserver">
            <component
              :is="component"
              v-for="(component, index) in components"
              :key="index" />
          </ValidationObserver>
        </div>
        <div class="col-md-5 col-lg-4 d-none d-md-block">
          <div class="sticky-sidebar">
            <Cart v-if="$store.state.scenario.currentStep.layout.show_cart" />
            <Nav v-if="showNav" />
          </div>
        </div>
        <div class="d-block d-md-none fixed-nav-mobile">
          <Cart v-if="$store.state.scenario.currentStep.layout.show_cart" />
          <Nav v-if="showNav" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { eventBus } from '@/eventBus';
import ProgressBar from './ProgressBar.vue';
import Cart from './Cart.vue';
import Nav from './Nav.vue';
import Headline from './layout/Headline.vue';
import HelpText from './HelpText.vue';
import { getStepObjectFromRoute } from '../../utils/scenarioUtils';

export default {
    components: {
        HelpText,
        Headline,
        ProgressBar,
        Nav,
        Cart,
        ValidationObserver,
    },
    computed: mapState('scenario', {
        components: (state) => state.currentStep.components,
        showNav: (state) => state.currentStep.next !== null,
    }),
    mounted() {
        /**
         * Validate step on load, in order to disable the Nav.vue button if needed.
         * The "silent" flag allows us to know if the step if valid, without showing the user
         * any error messages yet.
         */
        this.validateStep({ silent: true });

        /**
         * Listener to validate step on demand from different components.
         */
        eventBus.$on('validate-step', (args) => {
            this.validateStep(args);
        });

        /**
         * Listener to validate a specific field on demand from different components.
         */
        eventBus.$on('validate-field', (descriptor) => {
            this.validateField(descriptor);
        });

        /**
         * Listener to set errors on demand from different components.
         */
        eventBus.$on('set-errors', (errors) => {
            this.setRequestErrorsInStep(errors);
        });
    },
    methods: {
        validateStep(options = {}) {
            this.$nextTick(() => {
                this.$refs.stepObserver.validate(options).then((isValid) => {
                    this.$store.commit('status/common/setIsStepValid', isValid);

                    // Handle scroll to first error (if any)
                    const validationErrorEl = document.getElementsByClassName(
                        'validation-error__message',
                    )[0];
                    if (
                        !isValid
                        && !options.silent
                        && validationErrorEl
                    ) {
                        this.$scrollTo(validationErrorEl);
                    }
                });
            });
        },
        validateField(descriptor) {
            this.$nextTick(() => {
                this.$refs.stepObserver.refs[descriptor].validate().then(() => {
                    this.validateStep({ silent: true });
                });
            });
        },
        setRequestErrorsInStep(errors) {
            this.$refs.stepObserver.setErrors(errors);
        },
    },
    watch: {
        $route: {
            handler() {
                this.$store.commit(
                    'scenario/setCurrentStep',
                    getStepObjectFromRoute(this.$route.params.route),
                );
                this.validateStep({ silent: true });
            },
        },
    },
};
</script>
