<template>
  <modal v-if="showModalDefault">
    <h2 slot="header">
      {{ modalTitle }}
    </h2>
    <div slot="content" class="modal-website__body">
      <div v-if="modalError" class="loader__wrapper">
        Fehler
      </div>
      <div v-html="modalBody" />
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from './ModalBase.vue';

export default {
    data() {
        return {
            modalError: false,
            modalLoaded: false,
            modalBody: null,
            modalTitle: null,
        };
    },
    computed: mapState('status', {
        showModalDefault: (state) => state.common.showModalDefault,
    }),
    watch: {
        showModalDefault(newValue) {
            if (newValue) {
                this.populate();
            }
        },
    },
    methods: {
        populate() {
            this.modalTitle = this.$store.state.status.common.modalDefaultTitle;
            this.modalBody = this.$store.state.status.common.modalDefaultBody;
        },
        close() {
            this.$store.commit('status/common/showModalDefault', {
                show: false,
                title: null,
                body: null,
            });
        },
    },
    components: {
        Modal,
    },
};
</script>
