import store from '@/store';
import logger from './logging';

const eventObjectBuilder = (eventObject) => {
    if (eventObject === undefined) {
        logger(
            new Error('Missing tracking argument'),
        );
        return;
    }
    if (typeof eventObject !== 'object') {
        logger(
            new Error('Invalid tracking argument'),
        );
        return;
    }

    const output = {
        session_id: store.state.tracking.session_id,
        timestamp: Date.now(),
        application: 'signature',
        mode: store.state.settings.mode,
    };

    Object.keys(eventObject).forEach((key) => {
        output[key] = eventObject[key];
    });

    // eslint-disable-next-line
    return output;
};

/**
 * Function taking care of feeding the data layer on demand.
 * Takes an array as argument, like the following:
 * [eventCategory, eventAction, eventLabel, eventValue]
 * Note that eventValue is optional.
 * @param eventObject {object}
 */
const eventTracking = (eventObject) => {
    try {
        window.dataLayer.push(eventObjectBuilder(eventObject));
    } catch (error) {
        logger(error);
    }
};

// eslint-disable-next-line
export { eventTracking as eT };
