<template>
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-10 col-lg-8 app-status">
      <div class="text-center">
        <img
          :src="`./svg/${img}`"
          :alt="content.headline">
      </div>
      <p v-html="content.status_message" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        img: {
            type: String,
        },
    },
    computed: mapState('scenario', {
        content: (state) => state.currentStep.content,
    }),
};
</script>
