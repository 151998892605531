<template>
  <div class="justify-content-between cart-item">
    <div
      class="cart-item__label"
      v-html="label" />
    <div
      class="cart-item__amount"
      :class="{ 'cart-item__amount--discount': isDiscount }">
      {{ amount | price }}
      <span class="cart-item__suffix">{{ suffix }}</span>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
        },
        suffix: {
            type: String,
        },
        amount: {
            type: Number,
        },
        isDiscount: {
            type: Boolean,
        },
    },
};
</script>
