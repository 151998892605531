import common from './common';
import customerOffer from './customerOffer';
import maintenanceOffer from './maintenanceOffer';

export default {
    namespaced: true,
    modules: {
        common,
        customerOffer,
        maintenanceOffer,
    },
};
