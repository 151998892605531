<template>
  <div class="help-text__wrapper" v-if="showHelpTextContent">
    <div class="container-fluid help-text">
      <div v-html="help_text" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            help_text: (state) => state.scenario.currentStep.content.help_text,
            showHelpTextContent: (state) => state.status.common.showHelpTextContent,
        }),
    },
};
</script>
