<template>
  <div class="progress-bar__wrapper" v-if="showProgressBar">
    <div class="progress-bar">
      <div class="progress-bar__bubble__wrapper">
        <div
          v-for="(item, index) in arrayOfStepsToShow"
          :key="item.route"
          :class="{
            'progress-bar__bubble--active': currentStepIndex >= index,
          }"
          class="progress-bar__bubble">
          {{ index + 1 }}
        </div>
      </div>
      <div class="progress-bar__progress__wrapper">
        <div
          :style="{ width: `${percentage}%` }"
          class="progress-bar__progress" />
      </div>
    </div>
  </div>
</template>

<script>
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

export default {
    created() {
        this.arrayOfStepsToShow = filter(
            this.$store.state.scenario.steps,
            { count_as_progress: true },
        );
    },
    computed: {
        currentStepIndex() {
            return findIndex(
                this.arrayOfStepsToShow,
                (o) => o.route === this.$store.state.scenario.currentStep.route,
            );
        },
        showProgressBar() {
            return this.currentStepIndex !== -1;
        },
        percentage() {
            /**
             * Add an extra offset, for design purposes, so the progress bar always looks a little
             * longer than usual. The last step is excluded.
             */
            const offset = this.currentStepIndex !== (this.arrayOfStepsToShow.length - 1) ? 5 : 0;

            return ((this.currentStepIndex / (this.arrayOfStepsToShow.length - 1)) * 100) + offset;
        },
    },
};
</script>
