import common from './common';
import customerOffer from './customerOffer';
import contracting from './contracting';
import maintenanceOffer from './maintenanceOffer';

export default {
    namespaced: true,
    modules: {
        common,
        customerOffer,
        contracting,
        maintenanceOffer,
    },
};
