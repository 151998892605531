import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        isOfferExpired: false,
    },
    mutations: {
        setIsOfferExpired: (state, isOfferExpired) => {
            Vue.set(
                state,
                'isOfferExpired',
                isOfferExpired,
            );
        },
    },
};
