import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import './vee-validate';

Vue.config.productionTip = false;

if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    });
}

Vue.use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    cancelable: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
