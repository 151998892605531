<template>
  <div>
    <Headline />
    <div class="container-fluid step-loader">
      <div class="row">
        <Status img="contract_error.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import Status from '../Status.vue';
import Headline from '../layout/Headline.vue';

export default {
    components: {
        Headline,
        Status,
    },
};
</script>
