<template>
  <div class="d-md-none cart-total-mobile">
    <div class="cart-total-mobile__label">
      Gesamtpreis
    </div>
    <div class="cart-total-mobile__amount">
      <CartItem
        :key="total.label"
        v-for="total in totals"
        :suffix="total.suffix"
        :amount="total.price" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CartItem from './CartItem.vue';

export default {
    components: {
        CartItem,
    },
    computed: mapState('cart', {
        totals: (state) => state.totals,
    }),
};
</script>
