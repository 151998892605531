/* eslint-disable max-len */
const apiSettings = {
    backend: {
        rootUrl: process.env.VUE_APP_BACKEND_API_ROOT_URL,
    },
    homepage: {
        rootUrl: process.env.VUE_APP_HOMEPAGE_API_ROOT_URL,
        pageId: {
            impressum: process.env.VUE_APP_HOMEPAGE_API_IMPRESSUM_PAGE_ID,
            agb: process.env.VUE_APP_HOMEPAGE_API_AGB_PAGE_ID,
        },
    },
};
/* eslint-enable max-len */

export default apiSettings;
