import findIndex from 'lodash/findIndex';
import logger from '@/utils/logging';

export default {
    namespaced: true,
    state: {
        content: [],
        totals: [],
    },
    getters: {
        getTotalPrice(state) {
            return state.content.reduce((total, item) => total + item.price, 0);
        },
    },
    mutations: {
        /**
         * Add item to the cart (or replace if existing item with same uid).
         */
        addCartItem: (state, payload) => {
            if (!payload.uid) {
                logger(new Error(
                    'Cannot add item to cart: missing "uid".',
                ));
                return;
            }

            const existingItemIndex = findIndex(
                state.content,
                {
                    uid: payload.uid,
                },
            );

            if (existingItemIndex !== -1) {
                state.content.splice(existingItemIndex, 1, payload);
            } else {
                state.content.push(payload);
            }
        },

        /**
         * Remove an item from the cart.
         */
        deleteCartItem: (state, uid) => {
            if (!uid) {
                logger(new Error(
                    'Cannot remove item from the cart: missing "uid".',
                ));
                return;
            }

            const existingItemIndex = findIndex(
                state.content,
                {
                    uid,
                },
            );

            if (existingItemIndex !== -1) {
                state.content.splice(existingItemIndex, 1);
            }
        },

        /**
         * Used by the heating offer, as the total is received from the backend.
         */
        addCartTotal: (state, payload) => {
            if (!payload.uid) {
                logger(new Error(
                    'Cannot add item to cart: missing "uid".',
                ));
                return;
            }

            const existingItemIndex = findIndex(
                state.totals,
                {
                    uid: payload.uid,
                },
            );

            if (existingItemIndex !== -1) {
                state.totals.splice(existingItemIndex, 1, payload);
            } else {
                state.totals.push(payload);
            }
        },
    },
};
