<template>
  <transition name="modal">
    <div class="modal__mask">
      <div class="modal">
        <div class="modal__container">
          <button class="modal__close-btn" @click="$parent.close">
            <CloseSvg />
          </button>
          <div class="modal__title">
            <slot name="header" />
          </div>
          <div class="modal__content">
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseSvg from '../../../public/svg/close.svg';

export default {
    components: {
        CloseSvg,
    },
};
</script>
