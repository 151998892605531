<template>
  <modal v-if="showModalError">
    <h2 slot="header">
      Uuuuups!
    </h2>
    <div slot="content">
      <p>
        Es tut uns leid, aber Ihre Anfrage kann zurzeit nicht angenommen werden.
        Unsere Techniker arbeiten daran.
      </p>
      <p>
        Rufen Sie uns in der Zwischenzeit einfach kostenlos an unter
        <a href="tel:08004200300">0800 4 200 300</a>
        (Mo-Fr: 9-18Uhr) oder versuchen Sie es später erneut.
      </p>
      <div class="modal__go-to-homepage-cta-wrapper">
        <a href="https://www.thermondo.de/" class="btn btn-primary btn-lg">
          Zurück zur Startseite
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from './ModalBase.vue';

export default {
    computed: mapState('status', [
        'showModalError',
    ]),
    methods: {
        close() {
            this.$store.commit('status/common/showModalError', {
                show: false,
            });
        },
    },
    components: {
        Modal,
    },
};
</script>
