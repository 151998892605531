import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        isPackagePreSelectedInBackend: false,
        packagePreSelectedInBackend: undefined,
        touchedAddons: [],
    },
    getters: {
        isTouchedAddon: (state) => (addonId) => state.touchedAddons.includes(addonId),
    },
    mutations: {
        setIsPackagePreSelectedInBackend: (state, isPreSelected) => {
            Vue.set(
                state,
                'isPackagePreSelectedInBackend',
                isPreSelected,
            );
        },
        setPackagePreSelectedInBackend: (state, packageId) => {
            Vue.set(
                state,
                'packagePreSelectedInBackend',
                packageId,
            );
        },
        addTouchedAddon: (state, addonId) => {
            if (!state.touchedAddons.includes(addonId)) {
                state.touchedAddons.push(addonId);
            }
        },
    },
};
