/**
 * Route aliases, in order to keep it clean and easy to update.
 */

const customerAbsenceUrl = process.env.VUE_APP_CUSTOMER_ABSENCE_URL || null;

export default {
    customerOffer: {
        customer: 'kunde',
        offer: 'angebot',
        prepayment: 'anzahlung',
        checkout: 'bestellen',
        success: 'angebot-bestaetigung',
        error: 'fehler',
        customerAbsence: customerAbsenceUrl,
    },
    contracting: {
        customer: 'kunde',
        offer: 'angebot',
        bankDetails: 'bankeinzug',
        checkout: 'bestellen',
        success: 'angebot-bestaetigung',
        error: 'fehler',
        expired: 'abgelaufen',
        customerAbsence: customerAbsenceUrl,
    },
    maintenanceOffer: {
        package: 'wartungspaket',
        addons: 'zusatspakete',
        checkout: 'bestellen',
        success: 'angebot-bestaetigung',
        error: 'fehler',
        customerAbsence: null,

    },
};
