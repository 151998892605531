import { extend, localize } from 'vee-validate';
import { min, required } from 'vee-validate/dist/rules';

extend('required', required);
extend('min', min);
extend('germanIban', {
    validate: (value) => /^DE/.test(value),
    message: 'Bitte tragen Sie eine deutsche IBAN mit dem Ländercode DE ein.',
});

localize('de', {
    code: 'de',
    fields: {
        bankAccountOwner: {
            required: 'Bitte tragen Sie hier den Namen des Kontoinhabers ein.',
        },
        bankAccountIban: {
            required: 'Bitte tragen Sie hier die IBAN ein.',
        },
        bankAccountBankName: {
            required: 'Bitte tragen Sie hier den Namen Ihres Kreditinstitutes ein.',
        },
    },
});
