<template>
  <div
    class="controls d-flex justify-content-md-center"
    :class="[
      showPrices ? 'justify-content-between' : 'justify-content-end',
    ]"
    v-if="$store.state.scenario.currentStep.next">
    <CartTotalNav v-if="showPrices" />
    <a
      href="#"
      class="t-btn-1 controls__btn-forward align-self-center"
      :class="{ disabled: !isStepValid }"
      role="button"
      @click.prevent="submit()">
      <span class="loader-button" v-if="showButtonLoader" />
      {{ currentStep.content.button_forward }}
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import routeAliases from '@/config/routeAliases';
import mixinAppState from '@/mixins/mixin.appState';
import { eventBus } from '@/eventBus';
import logger from '../../utils/logging';
import CartTotalNav from './CartTotalNav.vue';
import { getStepObjectFromRoute } from '../../utils/scenarioUtils';
import { eT } from '../../utils/eventTracking';

export default {
    mixins: [
        mixinAppState,
    ],
    components: {
        CartTotalNav,
    },
    computed: {
        ...mapState({
            currentStep: (state) => state.scenario.currentStep,
            isStepValid: (state) => state.status.common.isStepValid,
            showPrices: (state) => state.scenario.currentStep.layout.show_cart,
            showButtonLoader: (state) => state.status.common.showButtonLoader,
        }),
    },
    methods: {
        submit() {
            if (this.isStepValid) {
                /**
                 * The current step might contain a specific request to run in order to
                 * validate user input against the backend before the user can move on.
                 */
                if (this.currentStep.request) {
                    /**
                     * If there is a "condition" function in the step, ensure that is passes before
                     * to execute the request. Otherwise, skip the request and navigate to the next
                     * step.
                     */
                    if (
                        this.currentStep.request.condition
                        && !this.currentStep.request.condition()
                    ) {
                        this.navigateToNextStep();
                        return;
                    }

                    /**
                     * Execute the request.
                     */
                    this.$store.commit('status/common/showButtonLoader', true);
                    this.currentStep.request.run()
                        .then(() => {
                            this.$store.commit(
                                'status/common/showButtonLoader',
                                false,
                            );

                            const step = getStepObjectFromRoute(this.$route.params.route);
                            const { mode } = this.$store.state.settings;

                            /**
                             * Track successful signature
                             */
                            if (step.route === routeAliases[mode].checkout) {
                                eT({
                                    event: 'contract_signed',
                                });
                            }

                            /**
                             * Set app state to success if reaching the success step.
                             * Otherwise, simply move on to the next step.
                             */
                            if (step.next === routeAliases[mode].success) {
                                const { customerAbsence } = routeAliases[mode];
                                // because we're leaving the app and coming back, we don't need
                                // to set the store to display our success page.
                                if (customerAbsence) {
                                    const { jwtToken } = this.$store.state.settings;
                                    window.location.replace((`${customerAbsence}?token=${jwtToken}`));
                                } else {
                                    this.setAppStateToSuccess();
                                }
                            } else {
                                this.navigateToNextStep();
                            }
                        })
                        .catch((error) => {
                            this.$store.commit(
                                'status/common/showButtonLoader',
                                false,
                            );

                            logger(error);

                            /**
                             * The current step might contain specific input validation messages.
                             */
                            if (
                                error.response.status === 400
                                && this.currentStep.request.validation
                            ) {
                                const fieldErrors = {};

                                Object
                                    .entries(error.response.data)
                                    .forEach((backendValidationError) => {
                                        const fieldErrorData = this.currentStep.request.validation[
                                            backendValidationError[0]
                                        ];

                                        fieldErrors[
                                            fieldErrorData.field
                                        ] = fieldErrorData.msg;
                                    });

                                eventBus.$emit(
                                    'set-errors',
                                    fieldErrors,
                                );
                            }
                        });
                } else {
                    this.navigateToNextStep();
                }
            } else {
                eventBus.$emit('validate-step');
            }
        },
        navigateToNextStep() {
            const { next } = getStepObjectFromRoute(this.$route.params.route);
            this.$router.push(next);
        },
    },
};
</script>
