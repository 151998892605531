<template>
  <header class="header__wrapper">
    <div class="container-fluid">
      <div class="row header">
        <div class="d-flex align-items-center">
          <img :src="`./svg/logo_${mode}.svg`">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('settings', {
            mode: (state) => state.mode || 'customerOffer',
        }),
    },
};
</script>
