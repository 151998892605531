import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        termsAccepted: false,
    },
    mutations: {
        setCollectedData: (state, payload) => {
            Vue.set(
                state,
                payload.field,
                payload.value,
            );
        },
        deleteCollectedData: (state, field) => {
            Vue.delete(
                state,
                field,
            );
        },
    },
};
