import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        package: undefined,
        addons: {
            solar_package: false,
            water_tank_package: false,
        },
    },
    mutations: {
        setPackage: (state, value) => {
            Vue.set(state, 'package', value);
        },
        setAddon: (state, payload) => {
            state.addons[payload.id] = payload.value;
        },
    },
};
