<template>
  <modal v-if="showModalWebsite">
    <h2 slot="header">
      {{ modalTitle }}
    </h2>
    <div slot="content" class="modal-website__body">
      <div v-if="!modalLoaded && !modalError" class="loader__wrapper">
        <span class="loader" />
      </div>
      <div v-if="modalError" class="loader__wrapper">
        Fehler
      </div>
      <div v-html="modalBody" />
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import has from 'lodash/has';
import logger from '@/utils/logging';
import Modal from './ModalBase.vue';

export default {
    data() {
        return {
            modalError: false,
            modalLoaded: false,
            modalTitle: null,
            modalBody: null,
        };
    },
    computed: mapState('status', {
        showModalWebsite: (state) => state.common.showModalWebsite,
    }),
    watch: {
        showModalWebsite(newValue) {
            if (newValue) {
                this.loadTerms();
            }
        },
    },
    methods: {
        async loadTerms() {
            const modalData = await this.getModalData();
            this.modalBody = modalData.body;
            this.modalTitle = modalData.title;
            this.modalLoaded = this.modalBody !== null;
        },
        async getModalData() {
            try {
                const { modalWebsitePageId } = this.$store.state.status.common;
                const urlRoot = process.env.VUE_APP_HOMEPAGE_API_ROOT_URL;
                const pagesEndpoint = process.env.VUE_APP_HOMEPAGE_API_PAGES_ENDPOINT_URL;
                const termsURL = `${urlRoot}${pagesEndpoint}${modalWebsitePageId}/?fields=body`; // ID 188 is the Terms & Conditions page
                const resp = await axios({
                    method: 'get',
                    url: termsURL,
                    responseType: 'json',
                });
                if (has(resp, 'data')) {
                    return resp.data;
                }
                return false;
            } catch (error) {
                logger(error);
            }
            return false;
        },
        close() {
            this.$store.commit('status/common/showModalWebsite', {
                show: false,
            });
            this.resetModal();
        },
        resetModal() {
            this.modalBody = null;
            this.modalTitle = null;
            this.modalLoaded = false;
        },
    },
    components: {
        Modal,
    },
};
</script>
