import store from '@/store';

/**
 * Provide multiple value helping to determine the current signature progress of the contract.
 */
export default function getContractStatus() {
    const currentSignature = store.state.settings.signature;
    const primaryCustomerSignature = store.state.contractData.customer.primary.hashed_id;
    const secondaryCustomerSignature = store.state.contractData.customer.secondary.hashed_id;

    /**
     * Does the contract have a secondary customer?
     */
    const hasSecondaryCustomer = store.state.contractData.has_secondary_customer;

    /**
     * Is it currently the signature for the primary customer?
     */
    const isPrimaryCustomer = currentSignature === primaryCustomerSignature;

    /**
     * Is it currently the signature for the secondary customer?
     */
    const isSecondaryCustomer = currentSignature === secondaryCustomerSignature;

    /**
     * Did the primary customer sign already?
     */
    const hasPrimaryCustomerSigned = store.state.contractData
        .contract_acceptance_status.primary_customer;

    /**
     * Did the secondary customer sign already?
     */
    const hasSecondaryCustomerSigned = store.state.contractData
        .contract_acceptance_status.secondary_customer;

    /**
     * Is it a primary or a secondary customer?
     */
    const customerType = isSecondaryCustomer ? 'secondary' : 'primary';

    return {
        hasSecondaryCustomer,
        isPrimaryCustomer,
        isSecondaryCustomer,
        hasPrimaryCustomerSigned,
        hasSecondaryCustomerSigned,
        customerType,
    };
}
