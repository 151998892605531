import status from './status';
import scenario from './scenario';
import contractData from './contractData';
import collectedData from './collectedData';
import settings from './settings';
import cart from './cart';
import tracking from './tracking';

export default {
    status,
    scenario,
    contractData,
    collectedData,
    settings,
    cart,
    tracking,
};
