import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        entry: undefined,
        steps: [],
        currentStep: {},
    },
    mutations: {
        setEntry: (state, entry) => {
            Vue.set(
                state,
                'entry',
                entry,
            );
        },
        setSteps: (state, steps) => {
            Vue.set(
                state,
                'steps',
                steps,
            );
        },
        setCurrentStep: (state, currentStep) => {
            Vue.set(
                state,
                'currentStep',
                currentStep,
            );
        },
    },
};
