import Vue from 'vue';
import { normalizeContractData } from '../../utils/dataAdapter';

export default {
    namespaced: true,
    state: {},
    mutations: {
        setContractData: (state, payload) => {
            Object
                .entries(normalizeContractData(
                    payload,
                ))
                .forEach(([key, value]) => {
                    Vue.set(
                        state,
                        key,
                        value,
                    );
                });
        },
    },
};
