<template>
  <div class="headline__wrapper">
    <div class="container-fluid headline">
      <div>{{ headline }}</div>
      <span
        class="headline__help-icon"
        @click="showHelpTextContent = !showHelpTextContent">
        <span v-if="!showHelpTextContent">
          <HelpSvg />
        </span>
        <span v-else>
          <CloseSvg />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelpSvg from '../../../../public/svg/help.svg';
import CloseSvg from '../../../../public/svg/close.svg';

export default {
    components: {
        HelpSvg,
        CloseSvg,
    },
    computed: {
        ...mapState({
            headline: (state) => state.scenario.currentStep.content.headline,
        }),
        showHelpTextContent: {
            get() {
                return this.$store.state.status.common.showHelpTextContent;
            },
            set(value) {
                this.$store.commit(
                    'status/common/showHelpTextContent',
                    value,
                );
            },
        },
    },
};
</script>
