<template>
  <div>
    <Loader v-if="$store.state.status.common.isAppLoading" />
    <div v-else>
      <Header />
      <router-view />
      <Footer />
    </div>
    <modal-error />
    <modal-website />
    <modal-default />
  </div>
</template>

<script>
import queryString from 'query-string';
import ModalError from '@/components/modals/ModalError.vue';
import getContractStatus from '@/utils/contractStatus';
import ModalWebsite from '@/components/modals/ModalWebsite.vue';
import ModalDefault from '@/components/modals/ModalDefault.vue';
import { getScenario, getStepObjectFromRoute } from './utils/scenarioUtils';
import { decodeJwt, getContractData } from './utils/requests';
import Header from './components/shared/layout/Header.vue';
import Footer from './components/shared/layout/Footer.vue';
import Loader from './components/shared/Loader.vue';
import logger from './utils/logging';
import mixinAppState from './mixins/mixin.appState';
import fillCart from './utils/fillCart';
import { eT } from './utils/eventTracking';

export default {
    mixins: [
        mixinAppState,
    ],
    components: {
        Loader,
        ModalError,
        ModalWebsite,
        ModalDefault,
        Header,
        Footer,
    },
    created() {
        this.init();
    },
    methods: {
        /**
         * Generate the event tracking session ID.
         */
        generateTrackingSessionId() {
            this.$store.commit('tracking/generateTrackingSessionId');
        },

        /**
         * Extract token from the URL and save it to the store for re-use.
         */
        getJwtTokenFromUrl() {
            /**
             * Initialise app settings using URL query params.
             */

            const { token } = queryString.parse(window.location.search);

            if (!token) {
                this.setAppStateToError();
            }

            return token;
        },

        /**
         * Set app scenario based on current "mode".
         */
        async setScenario() {
            const { mode } = this.$store.state.settings;
            const { entry, steps } = await getScenario(mode);

            if (entry && steps) {
                this.$store.commit('scenario/setEntry', entry);
                this.$store.commit('scenario/setSteps', steps);
                this.$store.commit('scenario/setCurrentStep', getStepObjectFromRoute(entry));
            } else {
                this.setAppStateToError();
                logger(new Error(
                    `No scenario called: ${mode}`,
                ));
            }
        },

        /**
         * All contract types have a Primary Customer. Some contracts have a Secondary Customer.
         * In order to apply modifications to the customer's journey in different places in the
         * application, we store "Contract Status" information for later use in scenario,
         * components, etc.
         */
        setContractStatus() {
            const {
                hasSecondaryCustomer,
                isPrimaryCustomer,
                isSecondaryCustomer,
                hasPrimaryCustomerSigned,
                hasSecondaryCustomerSigned,
                customerType,
            } = getContractStatus();

            this.$store.commit('settings/setHasSecondaryCustomer', hasSecondaryCustomer);
            this.$store.commit('settings/setIsPrimaryCustomer', isPrimaryCustomer);
            this.$store.commit('settings/setIsSecondaryCustomer', isSecondaryCustomer);
            this.$store.commit('settings/setHasPrimaryCustomerSigned', hasPrimaryCustomerSigned);
            this.$store.commit('settings/setHasSecondaryCustomerSigned', hasSecondaryCustomerSigned);
            this.$store.commit('settings/setCustomerType', customerType);
        },

        /**
         * Initialize the application.
         */
        async init() {
            this.generateTrackingSessionId();

            /**
             * Decode the JTW token and save its payload data to the store.
             */
            const jwtToken = this.getJwtTokenFromUrl();

            this.$store.commit('settings/setJwtToken', jwtToken);

            let jwtData;

            try {
                const response = await decodeJwt();
                jwtData = response.data;
            } catch (error) {
                logger(error);
                this.setAppStateToError();
            }

            if (jwtData && jwtData.mode && jwtData.url && jwtData.signature) {
                this.$store.commit('settings/setMode', jwtData.mode);
                this.$store.commit('settings/setUrl', jwtData.url);
                this.$store.commit('settings/setSignature', jwtData.signature);
            } else {
                logger(new Error(
                    '"mode", "url" or "signature" is missing.',
                ));
                this.setAppStateToError();
                return;
            }

            /**
             * For easier access to the mode.
             */
            const { mode } = this.$store.state.settings;

            /**
             * Get contract data and save it to the store.
             */
            let contractData;

            try {
                contractData = await getContractData();

                contractData = contractData.data;

                this.$store.commit(
                    'contractData/setContractData',
                    contractData,
                );
            } catch (error) {
                if (error.response && error.response.data) {
                    contractData = error.response.data;

                    eT({
                        event: 'error',
                        error_message: error.response.data.message,
                        error_response: error.response.data,
                    });
                }
            }

            /**
             * Stop init process and redirect to the expired route if offer has expired.
             */
            if (contractData.expired) {
                this.setAppStateToExpired();
                return;
            }

            /**
             * Set contract status (Primary customer? Secondary? Who has signed?).
             */
            this.setContractStatus();

            /**
             * Fill the "shopping cart" with necessary contract information.
             */
            fillCart(mode);

            /**
             * Start scenario.
             */
            await this.setScenario();
            this.$router.replace({
                path: this.$store.state.scenario.entry,
            });

            /**
             * Check if the contract has been signed already, and send to the success page when
             * needed.
             *
             * Note: As of now, the app only handle "contracting" (T365) contract type. This will
             * need to be refactored when other contract types will be supported again.
             */
            /* eslint-disable camelcase */
            const {
                hasSecondaryCustomer,
                hasPrimaryCustomerSigned,
                hasSecondaryCustomerSigned,
                isPrimaryCustomer,
                isSecondaryCustomer,
            } = this.$store.state.settings;

            if (hasSecondaryCustomer) {
                if (isPrimaryCustomer) {
                    if (hasPrimaryCustomerSigned) {
                        this.setAppStateToSuccess();
                    }
                }
                if (isSecondaryCustomer) {
                    if (hasSecondaryCustomerSigned) {
                        this.setAppStateToSuccess();
                    }
                }
            } else if (hasPrimaryCustomerSigned) {
                this.setAppStateToSuccess();
            }
            /* eslint-enable camelcase */

            /**
             * Turn off loading state, making the app usable.
             */
            this.$store.commit('status/common/setIsAppLoading', false);
        },
    },
};
</script>

<style lang="scss">
    @import 'scss/app.scss';
</style>
